import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Spinner,
  Image,
  Text,
  Tooltip,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Layout from "../../components/app/Layout";
import BaseContainer from "../../components/form/BaseContainer";
import { formatDate } from "../../utils/date.util";
import { IntegrationService } from "../../service/integration.service";
import { StatusTag } from "../../components/shared/StatusTag";
import { useParams } from "react-router-dom";
import { Loading } from "../../components/shared/Loading";
import BasePageTitleContainer from "../../components/form/BasePageTitleContainer";
import { FlexIconRow } from "../../components/shared/FlexIconRow";
import {
  CheckIcon,
  ClockIcon,
  CrossIcon,
  InfoIcon,
} from "../../components/shared/icons/icons";
import { formatSeconds } from "../../utils/number.util";
import { store } from "../../store";
import { successToast } from "../../constants/toast.constants";

const RUN_DEVIATION_THRESHOLD = 0.5;

const baseContainerStyles = {
  mx: "auto",
  padding: "0",
  flexGrow: "1",
  borderRadius: { base: "0px", lg: "8px 8px 0 0" },
  minWidth: "95%",
  minH: { base: "calc(100vh - 80px - 24px)" },
  paddingBottom: "50px",
};

const integrationService = new IntegrationService();

export const History: React.FC<any> = (props: any) => {
  const { id } = useParams();
  const toast = useToast();

  const [runs, setRuns] = useState([] as any[]);
  const [loading, setLoading] = useState(true);
  const [metric, setMetrics] = useState({
    totalDuration: 0,
    averageDuration: 0,
  } as any);

  const [focussedRun, setFocussedRun] = useState({
    id: null,
    status: null,
    logs: [],
  });

  const { workflows } = store.getState().sidebar;

  const init = async () => {
    setLoading(true);
    await setWorkflowData().finally(() => {
      setLoading(false);
    });
  };

  const handleToggle = async (runId: string) => {
    if (focussedRun.id === runId) {
      setFocussedRun({ id: null, status: null, logs: [] });
      return;
    }

    setFocussedRun((prev) => ({
      ...prev,
      id: runId === prev.id ? null : runId,
    }));

    await setRunHistory(runId);
  };

  const setWorkflowData = async () => {
    await integrationService.getWorkflowRunsHistory(id).then((history) => {
      setRuns(history);
      setMetrics({
        totalDuration: history?.reduce((acc, x) => acc + x.duration, 0),
        averageDuration: Math.round(
          history?.reduce((acc, x) => acc + x.duration, 0) /
          (history.length || 1)
        ),
      });
    });
  };

  const setRunHistory = async (runId: string) => {
    const run = await integrationService.getWorkflowRunLog({
      integrationName: "RWG-Daily",
      workflowId: id,
      workflowRunId: runId,
    });

    const uniqueMessages = run?.logs?.reduce((acc, x) => {
      if (!acc.find((y) => y.Message.S === x.Message.S)) {
        acc.push(x);
      }
      return acc;
    }, []);

    setFocussedRun({ id: runId, status: run?.status, logs: uniqueMessages });

    if (run?.status === "Completed") {
      await setWorkflowData();
    }

    toast(successToast('Logs refreshed.'))
  };

  const runDurationAcceptable = (duration: number) => {
    const deviation = Math.abs(duration - metric.averageDuration);
    const deviationPercentage = deviation / metric.averageDuration;
    
    console.log(deviationPercentage, duration, metric.averageDuration, deviation)
    return deviationPercentage < RUN_DEVIATION_THRESHOLD
  }

  useEffect(() => {
    init();
  }, [id]);

  return (
    <Layout>
      <Loading loading={loading} />
      <BasePageTitleContainer minW="95%">
        <Flex flexDir="row" justifyContent="start" alignItems="center" w="100%">
          <Text fontWeight="600">
            {runs[0]?.friendlyName || "Workflow"} History
          </Text>
        </Flex>
        <Text mt="12px">
          {workflows?.find((x) => +x.id === +id)?.description}.
        </Text>
        <Box fontSize="12px" color="gray">
          Displaying top {runs?.length > 30 ? "30" : runs?.length ?? 0} results.
        </Box>

        <Divider my="12px" />

        <Flex flexDir="row" gap="8px" alignItems="center">
          <FlexIconRow>
            <CheckIcon
              fill={
                runs?.filter((x) => x.conclusion === "success").length
                  ? "green"
                  : undefined
              }
            />
            {runs?.filter((x) => x.conclusion === "success").length} success
          </FlexIconRow>
          <Box color="gray">•</Box>
          <FlexIconRow>
            <CrossIcon
              fill={
                runs?.filter((x) => x.conclusion === "failure").length
                  ? "red"
                  : undefined
              }
            />
            {runs?.filter((x) => x.conclusion === "failure").length} failed
          </FlexIconRow>
          <Box color="gray">•</Box>
          <FlexIconRow>
            <InfoIcon
              fill={
                runs?.filter((x) => !runDurationAcceptable(x.duration)).length
                  ? "orange"
                  : undefined
              }
            />
            {
              runs?.filter((x) => !runDurationAcceptable(x.duration)).length
            }{" "}
            requiring attention
          </FlexIconRow>
        </Flex>

        <Divider my="12px" />
        <FlexIconRow>
          <ClockIcon />
          {formatSeconds(metric.totalDuration)} total duration (average{" "}
          {formatSeconds(metric.averageDuration)} per run)
        </FlexIconRow>
      </BasePageTitleContainer>

      <BaseContainer styles={baseContainerStyles}>
        <Accordion allowToggle>
          {runs?.map((run: any, i: number) => (
            <AccordionItem key={run.id}>
              <AccordionButton key={i} onClick={() => handleToggle(run.id)}>
                <Box py="3px" as="span" flex="1" textAlign="left">
                  <Flex
                    flexDirection="row"
                    justifyContent="space-between"
                    w="80%"
                  >
                    <FlexIconRow gap="18px">
                      {!!run.conclusion ? (
                        <>
                          {!runDurationAcceptable(run.duration) && (
                            <>⚠️</>
                          )}
                          <StatusTag
                            type={run.conclusion}
                            style={{ width: "120px" }}
                          >
                            {run.conclusion}
                          </StatusTag>
                          <Box>{formatDate(run.lastRunDate)}</Box>
                          <Box></Box>
                        </>
                      ) : (
                        <>
                          <Spinner />
                          <Box>
                            {focussedRun?.status === 'Completed' && focussedRun.id === run.id ? '✅ Integration has completed. We are cleaning up now, but your Anaplan models should be updated.' : <Box
                              cursor="pointer"
                              _hover={{ textDecor: "underline" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setRunHistory(run.id);
                              }}
                            >
                              Currently runnning. Cick here to refresh.
                            </Box>}
                          </Box>
                        </>
                      )}
                    </FlexIconRow>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <br />
                {run.conclusion && (
                  <>
                    <Flex flexDirection="column" gap="16px">
                      <Text>
                        Completed with status {run.conclusion} in {run.attempt}{" "}
                        attempt(s).
                      </Text>
                      <Text>Duration: {formatSeconds(run.duration)}</Text>
                      {!runDurationAcceptable(run.duration) && (
                        <Box fontSize="14px" color="red">We have flagged this run as Requiring Attention as the Run Duration deviates greatly from the average.</Box>
                      )}
                    </Flex>
                  </>
                )}

                <Divider my="12px" />

                {focussedRun.id === run.id && !!focussedRun?.logs?.length ? (
                  <Flex
                    flexDir="column"
                    p="20px"
                    bg="black"
                    color="white"
                    borderRadius="8px"
                  >
                    {focussedRun.logs.map((log: any, i: number) => (
                      <Flex key={i} flexDir="row" gap="12px">
                        <Text>{log.Message?.S ?? "--"}</Text>
                      </Flex>
                    ))}
                    {focussedRun.status === "Completed" ? (
                      <Flex flexDir="row" justifyContent="center">
                        - Integration has completed -
                      </Flex>
                    ) : (
                      <Flex
                        flexDir="row"
                        justifyContent="center"
                        color="yellow"
                        cursor="pointer"
                        _hover={{ textDecor: "underline" }}
                        onClick={() => setRunHistory(run.id)}
                      >
                        - Load More -
                      </Flex>
                    )}
                  </Flex>
                ) : (
                  <>
                    {!!run.conclusion ? (
                      "No logs are available for this integration run."
                    ) : (
                      <Box
                        cursor="pointer"
                        _hover={{ textDecor: "underline" }}
                        onClick={() => setRunHistory(run.id)}
                      >
                        Currently runnning. Cick here to refresh.
                      </Box>
                    )}
                  </>
                )}

                <Box></Box>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
        <Box fontSize="14px" position="absolute" p="16px" bottom="0">
          Displaying top 30 results.
        </Box>
      </BaseContainer>
    </Layout>
  );
};
