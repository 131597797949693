import {
  Box,
  Divider,
  Flex,
  Image,
  Spinner,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Layout from "../../components/app/Layout";
import BaseContainer from "../../components/form/BaseContainer";
import { RepeatIcon } from "@chakra-ui/icons";
import { IntegrationService } from "../../service/integration.service";
import { IntegrationItem } from "../../models/integration-item.model";
import { StatusTag } from "../../components/shared/StatusTag";
import { Loading } from "../../components/shared/Loading";
import { errorToast, successToast } from "../../constants/toast.constants";
import { useNavigate } from "react-router-dom";
import { WorkflowTriggerModal } from "../../components/modal/WorkflowTriggerModal";
import { ButtonCustom } from "../../components/form/controls/ButtonCustom";
import { ExportArchiveModal } from "../../components/modal/ExportArchiveModal";
import { MagiclinkService } from "../../service/third-party/magiclink.service";
import { TableContainer } from "../../components/shared/table/TableContainer";
import BasePageTitleContainer from "../../components/form/BasePageTitleContainer";
import { FlexIconRow } from "../../components/shared/FlexIconRow";
import { LinkIcon } from "../../components/shared/icons/icons";
import { formatDate } from "../../utils/date.util";
import { firstCharToUpper } from "../../utils/string.util";

const baseContainerStyles = {
  mx: "auto",
  padding: "0",
  flexGrow: "1",
  borderRadius: { base: "0px", lg: "8px 8px 0 0" },
  minWidth: "95%",
};

const integrationService = new IntegrationService();
const magiclinkService = new MagiclinkService();

export const Integrations: React.FC<any> = (props: any) => {
  const [integrations, setIntegrations] = useState([] as IntegrationItem[]);
  const [loading, setLoading] = useState(true);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [activeId, setActiveId] = useState("");

  const [archiveModalProps, setArchiveModalProps] = useState({ repo: "" });
  const [enableFeature, setEnableFeature] = useState(false);

  const [latestRanIntegration, setLatestRanIntegration] = useState({} as IntegrationItem);

  const toast = useToast();
  const navigate = useNavigate();

  const init = async () => {
    setLoading(true);
    await setIntegrationData().finally(() => {
      setLoading(false);
    });

    const email = await magiclinkService.userEmail();
    if (email.endsWith("@nfiny.com") || email.endsWith("@sumlookup.com.au")) {
      setEnableFeature(true);
    }
  };

  const setIntegrationData = async () => {
    const integrations = await integrationService.getWorkflowsWithRuns({
      limit: 1,
    });

    // order by lastRunDate
    integrations.sort((a, b) => {
      return new Date(b.lastRunDate).getTime() - new Date(a.lastRunDate).getTime();
    });

    setIntegrations(integrations);
  };

  const triggerWorkflow = async () => {
    await integrationService
      .triggerWorkflow(activeId)
      .then(() => {
        toast(successToast("Integration started."));
        navigate(`/history/${activeId}`);
      })
      .catch(() => {
        toast(errorToast("Integration failed to start."));
      });
  };

  const onTriggerWorkflowClick = async (workflowId: string) => {
    setWarningModalOpen(true);
    setActiveId(workflowId);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Layout>
      <Loading loading={loading} />
      <BasePageTitleContainer minW="95%">
        <Flex flexDir="row" justifyContent="space-between" alignItems="center" w="100%">
          <Text fontWeight="600">
            Active Integrations
          </Text>
          <ButtonCustom
            hidden={!enableFeature}
            onClick={() => setExportModalOpen(!exportModalOpen)}
          >
            Export Archives{" "}
          </ButtonCustom>
        </Flex>

        <Divider my="12px" />

        <FlexIconRow>
          <LinkIcon />
          <Box>{integrations?.length} integrations</Box>
        </FlexIconRow>
      </BasePageTitleContainer>

      <BaseContainer styles={baseContainerStyles}>
        <TableContainer columns={tableColumns}>
          <Tbody>
            {/* these are workflows */}
            {integrations?.map((integration: IntegrationItem, i: number) => (
              <Tr key={i}>
                <Td
                  onClick={() => navigate(`/history/${integration.id}`)}
                  _hover={{ color: "#29ab02", cursor: "pointer" }}
                >
                  <Flex flexDir="row" gap="8px" alignItems="center">
                    <Spinner w="14px" h="14px" hidden={!!integration.conclusion} />
                    {integration.friendlyName}
                  </Flex>
                </Td>
                <Td>{integration.description}</Td>
                <Td>{formatDate(integration.lastRunDate)}</Td>
                <Td>
                  <StatusTag
                    type={integration.conclusion as any}
                    style={{ width: "120px" }}
                  >
                    {firstCharToUpper(integration.conclusion)}
                  </StatusTag>
                </Td>
                <Td>
                  <Flex
                    hidden={!integration.conclusion}
                    flexDirection="row"
                    justifyContent="flex-end"
                    gap="16px"
                  >
                    <Tooltip placement="left" label={`Re-run ${integration.friendlyName}`}>
                      <RepeatIcon
                        cursor="pointer"
                        w="18px"
                        h="18px"
                        _hover={{ color: "#29ab02" }}
                        onClick={() => onTriggerWorkflowClick(integration.id)}
                      />
                    </Tooltip>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </TableContainer>
      </BaseContainer>

      <ExportArchiveModal
        isOpen={exportModalOpen}
        onClose={() => {
          setExportModalOpen(false);
          setActiveId("");
        }}
        integrations={integrations}
      />

      <WorkflowTriggerModal
        isOpen={warningModalOpen}
        onClose={() => {
          setWarningModalOpen(false);
          setActiveId("");
        }}
        onConfirm={triggerWorkflow}
      />
    </Layout>
  );
};

const tableColumns = [
  { header: "Name" },
  { header: "Description" },
  { header: "Last run", style: { width: "220px" } },
  { header: "Last status", style: { width: "160px" } },
  { header: "", style: { width: "100px", textAlign: "right", paddingRight: "16px" } },
]