import { Button } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";

export const ButtonCustom: React.FC<{
  children: any;
  onClick?: () => void;
  size?: "sm" | "md" | "lg";
  rounded?: boolean;
  secondary?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  neutral?: boolean;
  danger?: boolean;
  hidden?: boolean;
  style?: any; // Pass in custom styles. i.e. <ButtonCustom style={{ width: "100%" }}>Test BTN</ButtonCustom>
}> = (props) => {
  const getWidth = () => {
    switch (props.size) {
      case "sm":
        return "80px";
      case "md":
        return "120px";
      case "lg":
        return "160px";
      //...
      default:
        return "hug";
    }
  };

  let properties = {
    hidden: props.hidden,
    isDisabled: props.disabled,
    type: props.type,
    variant: "container",
    onClick: props.onClick,
    width: getWidth(),
    borderRadius: props.rounded ? "100px" : "4px",
    style: { ...props.style },
  } as any;

  if (props.danger) {
    return <DangerButton {...properties}>{props.children}</DangerButton>;
  }

  if (props.neutral) {
    return <NeutralButton {...properties}>{props.children}</NeutralButton>;
  }

  if (props.secondary) {
    return <SecondaryButton {...properties}>{props.children}</SecondaryButton>;
  }

  return <ButtonComponent {...properties}>{props.children}</ButtonComponent>;
};

const StyledButton = styled(Button)({
  boxShadow: "none !important",
  padding: "8px 12px",
  fontSize: "15px",
  fontWeight: 600,
  border: "none",
  textTransform: "capitalize",
});

const ButtonComponent = styled(Button)({
  fontSize: "15px",
  color: "white",
  backgroundColor: "#BF326A",
});

const SecondaryButton = styled(StyledButton)({
  fontSize: "15px",
  backgroundColor: "#4F46E5",
  color: "white",
});

const NeutralButton = styled(StyledButton)({
  fontSize: "15px",
  backgroundColor: "transparent",
  border: "1px solid #D4D4D8",
});

const DangerButton = styled(StyledButton)({
  fontSize: "15px",
  backgroundColor: "#FEE2E2",
  border: "none",
  color: "#B91C1C",
});
