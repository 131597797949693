import { Box, Container } from "@chakra-ui/react";
import React from "react";

const BaseContainer: React.FC<any> = (props: any) => {
  const { children, styles } = props;

  return (
    <Box
      width={{ md: "100%" }}
      maxWidth={{ base: "100%", md: "640px" }}
      mt={{ base: "0", md: "24px" }}
      mx={{ base: "16px", md: "auto" }}
      p="24px"
      borderRadius="8px"
      bg="white"
      filter="drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))"
      {...styles}
    >
      <Box>{children}</Box>
    </Box>
  );
};

export default BaseContainer;
