import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  Image,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DownloadIcon,
  LinkIcon,
  LockIcon,
  PhoneIcon,
  RepeatClockIcon,
  StarIcon,
} from "@chakra-ui/icons";
import { Workflow } from "../../models/github/workflow.model";
import { IntegrationService } from "../../service/integration.service";
import { useDispatch } from "react-redux";
import { update as tokenUpdate } from "../../store/slice/Token/token.slice";
import { MagiclinkService } from "../../service/third-party/magiclink.service";
import { updateSidebar } from "../../store/slice/Sidebar/sidebar.slice";
import { store } from "../../store";
import styles from "../../styles/Home.module.css";

const links = ["integrations", "history"];

const integrationService = new IntegrationService();
const magiclinkService = new MagiclinkService();

export const Sidebar: React.FC<any> = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { logoSrc, workflows } = store.getState().sidebar;

  const logout = async () => {
    dispatch(tokenUpdate({}));
    dispatch(updateSidebar({ logoSrc: "", workflows: [] }));
    await magiclinkService.logout();
    navigate("/auth/app/login");
  };

  const init = async () => {
    const workflows = await integrationService.getWorkflows();
    const email = await magiclinkService.userEmail();

    let src = "";
    const company = email.split("@")[1].split(".")[0];
    src = `/assets/company-logo/${company.toLocaleLowerCase()}.png`;
    
    dispatch(
      updateSidebar({
        logoSrc: src,
        workflows: workflows.map((x) => ({
          id: x.id,
          friendlyName: x.friendlyName,
          description: x.workflowDescription
        })),
      })
    );
  };

  useEffect(() => {
    if (!workflows?.length) {
      init();
    }
  }, []);

  return (
    <Box
      maxW="290px"
      minH="100%"
      display={{ base: "none", lg: "block" }}
      position="fixed"
    >
      <Flex
        minH="100vh"
        bg="brand.100"
        w="290px"
        p="24px 12px 12px 12px"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <a onClick={() => navigate("/dashboard")}>
            {logoSrc ? (
              <Image
                src={logoSrc}
                alt="Company Logo"
                width="160px"
                mb={6}
                ml="12px"
                cursor="pointer"
              />
            ) : (
              <></>
            )}
          </a>

          <LinkStyled
            active={window.location.pathname.startsWith(`/dashboard`)}
            onClick={() => navigate(`/dashboard`)}
          >
            <Flex flexDirection="row" gap={2} alignItems={"center"}>
              <Icon link={"dashboard"} />
              <Text>Dashboard</Text>
            </Flex>
          </LinkStyled>

          <LinkStyled
            active={window.location.pathname.startsWith(`/integrations`)}
            onClick={() => navigate(`/integrations`)}
          >
            <Flex flexDirection="row" gap={2} alignItems={"center"}>
              <Icon link={"integrations"} />
              <Text>Manual Trigger</Text>
            </Flex>
          </LinkStyled>

          <LinkStyled
            active={window.location.pathname.startsWith(`/archive`)}
            onClick={() => navigate(`/archive`)}
          >
            <Flex flexDirection="row" gap={2} alignItems={"center"}>
              <Icon link={"archive"} />
              <Text>Archive & Logs</Text>
            </Flex>
          </LinkStyled>

          <Accordion allowToggle defaultIndex={1}>
            <AccordionItem
              color="white"
              borderTop="none"
              borderBottom="none"
              borderRadius="md"
            >
              <AccordionButton
                borderRadius="6px"
                py="12px"
                fontSize="14px"
                _expanded={{ bg: "brand.200", color: "white" }}
              >
                <Flex fontWeight="bold" flexDir="row" gap="8px">
                  <Icon link={"history"} />
                  History
                </Flex>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel className={styles["scroll-container"]}>
                {workflows?.map((workflow, i) => {
                  return (
                    <SublinkStyled
                      active={window.location.pathname.startsWith(
                        `/history/${workflow.id}`
                      )}
                      key={i}
                      onClick={() => navigate(`/history/${workflow.id}`)}
                    >
                      <Flex flexDirection="row" gap={2} alignItems={"center"}>
                        <Link as={LinkIcon} />
                        <Text fontWeight="500" fontSize="12px">{workflow.friendlyName}</Text>
                      </Flex>
                    </SublinkStyled>
                  );
                })}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>

        <Box>
          <LinkStyled
            active={window.location.pathname.startsWith(`/support`)}
            onClick={() => navigate(`/support`)}
          >
            <Flex flexDirection="row" gap={2} alignItems={"center"}>
              <Icon link={"support"} />
              <Text>Support</Text>
            </Flex>
          </LinkStyled>

          <Box borderTop="1px solid brand.200">
            <Box>
              <LinkStyled onClick={logout}>
                <Flex flexDirection="row" gap={2} alignItems={"center"}>
                  <Link as={LockIcon} />
                  <Text>End Session</Text>
                </Flex>
              </LinkStyled>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

const LinkStyled: React.FC<any> = (props: any) => {
  const { href, children, active, onClick } = props;

  return (
    <Button
      onClick={onClick}
      fontSize="14px"
      boxSize="initial"
      justifyContent="flex-start"
      flexDirection={"row"}
      alignItems="center"
      bg={active ? "brand.200" : "brand.100"}
      mb={{
        lg: "12px",
      }}
      mx={{
        xl: "auto",
      }}
      ps={{
        sm: "10px",
        xl: "16px",
      }}
      _hover={{
        bg: "brand.200",
      }}
      py="12px"
      color="white"
      borderRadius="8px"
      w="100%"
    >
      {children}
    </Button>
  );
};

const SublinkStyled: React.FC<any> = (props: any) => {
  const { href, children, active, onClick } = props;

  return (
    <Button
      onClick={onClick}
      fontSize="14px"
      boxSize="initial"
      justifyContent="flex-start"
      flexDirection={"row"}
      alignItems="center"
      bg={active ? "brand.200" : "brand.100"}
      mb={{
        lg: "12px",
      }}
      mx={{
        xl: "auto",
      }}
      ps={{
        sm: "10px",
        xl: "16px",
      }}
      _hover={{
        bg: "brand.200",
      }}
      py="12px"
      color="white"
      borderRadius="8px"
      w="100%"
    >
      {children}
    </Button>
  );
};

const Icon: React.FC<any> = (props: any) => {
  const { link } = props;

  if (link === "integrations") return <Link as={LinkIcon} />;
  if (link === "history") return <Link as={RepeatClockIcon} />;
  if (link === "dashboard") return <Link as={StarIcon} />;
  if (link === "archive") return <Link as={DownloadIcon} />;
  if (link === "support") return <Link as={PhoneIcon} />;
};

const scrollbarStyle = {
  height: "20vw",
  overflowY: "auto",
  overflowX: "hidden",
};

const webkitScrollbarStyle = {
  "&::-webkit-scrollbar": {
    backgroundColor: "transparent",
  },
};
