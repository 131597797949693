import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import tokenReducer from "./slice/Token/token.slice";
import storage from "reduxjs-toolkit-persist/lib/storage";
import autoMergeLevel1 from "reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1";
import {
  persistCombineReducers,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "reduxjs-toolkit-persist";
import tokenSlice from "./slice/Token/token.slice";
import userProfileSlice from "./slice/Account/user-profile.slice";
import sidebarSlice from "./slice/Sidebar/sidebar.slice";

const persistConfig = {
  key: "root",
  timeout: 2000,
  storage: storage,
  stateReconciler: autoMergeLevel1,
};

const _persistedReducer = persistCombineReducers(persistConfig, {
  token: tokenSlice,
  userProfile: userProfileSlice,
  sidebar: sidebarSlice,
});

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      /* ignore persistance actions */
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
