import { Box, Center, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export const Loading: React.FC<any> = (props: any) => {
  const { loading } = props;
  const [showLoading, setShowLoading] = useState(false);

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const init = async () => {
    await delay(700);
    setShowLoading(true);
  };

  useEffect(() => {
    init();
  }, [loading]);

  useEffect(() => {
    if (!loading) setShowLoading(false);
  }, [loading]);

  if (loading && showLoading) {
    return (
      <Box
        position="fixed"
        top="0"
        left="290px"
        w={"calc(100vw - 290px)"}
        h="100%"
        zIndex={99}
        opacity="0.9"
        bg="gray"
      >
        <Center>
          <Spinner
            top="50%"
            position="absolute"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      </Box>
    );
  }

  return <></>;
};
