import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
} from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { IntegrationItem } from "../../models/integration-item.model";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  data: IntegrationItem[];
}

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
  options: {
    maintainAspectRatio: false,
  },
  scales: {
    y: {
      title: {
        display: true,
        text: "Frequency",
        font: {
          size: 14,
          weight: "bold",
        },
      },
      grid: {
        drawOnChartArea: false, // remove y-axis gridlines
      },
    },
    x: {
      grid: {
        drawOnChartArea: false, // remove y-axis gridlines
      },
    },
  },
} as any;

export const RunFrequencyChart: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const [chartProps, setChartProps] = useState();
  const [guid, setGuid] = useState(0);

  const init = () => {
    const result = data.reduce((acc, { friendlyName, attempt }) => {
      const existingIndex = acc.findIndex(
        (item) => item.friendlyName === friendlyName
      );
      if (existingIndex !== -1) {
        acc[existingIndex].attempt += attempt;
      } else {
        acc.push({ friendlyName, attempt });
      }
      return acc;
    }, [] as any[]);

    const props = {
      labels: result.map((x) => x.friendlyName),
      datasets: [
        {
          label: "Run Frequency",
          data: result.map((x) => x.attempt),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };

    setChartProps(props as any);
    setGuid(guid + 1);
  };

  useEffect(() => {
    init();
  }, [data]);

  return !chartProps ? (
    <>Loading...</>
  ) : (
    <Bar options={options} data={chartProps} key={guid} redraw={true} />
  );
};
