import { RepeatIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import Layout from "../../components/app/Layout";
import { FailureChart } from "../../components/dashboard/FailureChart";
import { RunFrequencyChart } from "../../components/dashboard/RunRequencyChart";
import { RunTimeChart } from "../../components/dashboard/RunTimeChart";
import BaseContainer from "../../components/form/BaseContainer";
import { Loading } from "../../components/shared/Loading";
import { StatusTag } from "../../components/shared/StatusTag";
import { IntegrationItem } from "../../models/integration-item.model";
import { IntegrationService } from "../../service/integration.service";
import { formatDate } from "../../utils/date.util";
import "../../styles/chart.css";
import { formatSeconds } from "../../utils/number.util";

const baseContainerStyles = {
  mx: "auto",
  padding: "0",
  flexGrow: "1",
  borderRadius: { base: "0px", lg: "8px 8px 0 0" },
  minWidth: "95%",
  minH: { base: "calc(100vh - 80px - 24px)" },
  backgroundColor: "transparent",
};

const integrationService = new IntegrationService();

export const Dashboard: React.FC<any> = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [monthRuns, setMonthRuns] = useState([] as IntegrationItem[]);
  const [runs, setRuns] = useState([] as IntegrationItem[]);
  const [averageTime, setAverageTime] = useState(0);
  const [integrations, setIntegrations] = useState([] as IntegrationItem[]);

  const useFirstDayOfMonth = () => {
    const firstDayOfMonth = useMemo(() => {
      const date = new Date();
      const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
      return firstDate.toISOString();
    }, []);

    return firstDayOfMonth;
  };

  const firstDayOfMonth = useFirstDayOfMonth();

  const init = async () => {
    setLoading(true);
    await setIntegrationData().finally(() => {
      setLoading(false);
    });
  };

  const setIntegrationData = async () => {
    await integrationService.getWorkflowsWithRuns().then((runs) => {
      // setRuns(runs);
      const dateFiltered = runs.filter(
        (run) => new Date(run.lastRunDate) >= new Date(firstDayOfMonth)
      );

      const avgTime =
        dateFiltered
          .map((x) => x.duration)
          .reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          ) / dateFiltered.length;

      setAverageTime(+avgTime.toFixed(2));
      setMonthRuns(dateFiltered);
    });

    await integrationService.getWorkflowCount().then((resp) => {
      setCount(resp.count);
    });

    const integrations = await integrationService.getWorkflowsWithRuns({
      limit: 1,
    });

    setIntegrations(integrations);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Layout>
      <Loading loading={loading} />

      <BaseContainer styles={baseContainerStyles}>
        <Text
          p="6px"
          fontSize="14px"
          w="fit-content"
          mb="6px"
        >
          Reporting Period: 01{" "}
          {new Date().toLocaleString("default", { month: "long" })} - current
        </Text>
        <Column>
          <Row>
            <Item>
              Active Integrations
              <HeaderContainer>{count}</HeaderContainer>
            </Item>
            <Item>
              Time to Execute (month avg)
              <HeaderContainer>
                {formatSeconds(averageTime)}
              </HeaderContainer>
            </Item>
            <Item>
              Total Failures (month)
              <HeaderContainer>
                {monthRuns.filter((x) => x.conclusion === "failure").length}
              </HeaderContainer>
            </Item>
            <Item>
              Total Run Attempts (month)
              <HeaderContainer>
                {monthRuns
                  .map((x) => x.attempt)
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  )}
              </HeaderContainer>
            </Item>
          </Row>
          <Row w="100%" justifyContent="space-between">
            <Item minW="74.9%" h="50vh" maxH="500px">
              <SectionTitle>Run Frequency </SectionTitle>
              <Center>
                <Box w="100%" h="40vh" maxH="420px">
                  <RunFrequencyChart data={monthRuns} />
                </Box>
              </Center>
            </Item>
            <Item minW="23%" h="50vh" maxH="500px">
              <SectionTitle>Integration Fails </SectionTitle>
              <Center>
                <Box p="10px" w="100%" h="40vh" maxH="500px">
                  <FailureChart data={monthRuns} />
                </Box>
              </Center>
            </Item>
          </Row>

          <Row>
            <Item minW="100%" h="60vh" maxH="500px">
              <SectionTitle>Run Times (avg)</SectionTitle>
              <Center>
                <Box w="100%" h="55vh" p="30px" maxH="450px">
                  <RunTimeChart data={monthRuns} />
                </Box>
              </Center>
            </Item>
            {/* <Item minW="40%">
              <SectionTitle>Breakdown</SectionTitle>
              <Center>
                <Box w="100%"></Box>
              </Center>
            </Item> */}
          </Row>

          <Item overflow="auto" minW="100%" h="50vh">
            <SectionTitle>Active Integrations</SectionTitle>
            <TableContainer>
              <Table size="lg">
                <Thead
                  style={{ position: "sticky", top: "0", background: "white" }}
                >
                  <Tr>
                    <Th>Name</Th>
                    <Th>Description</Th>
                    <Th>Last Run</Th>
                    <Th>Last Run Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {/* these are workflows */}
                  {integrations?.map(
                    (integration: IntegrationItem, i: number) => (
                      <Tr key={i}>
                        <Td>{integration.friendlyName}</Td>
                        <Td>{integration.description}</Td>
                        <Td>{formatDate(integration.lastRunDate)}</Td>
                        <Td>
                          <StatusTag
                            type={integration.conclusion as any}
                            style={{ width: "120px" }}
                          >
                            {integration.conclusion}
                          </StatusTag>
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Item>
        </Column>
      </BaseContainer>
    </Layout>
  );
};

const SectionTitle = styled(Text)({
  fontSize: "20px",
  marginBottom: "12px",
  fontWeight: "bold",
});

const HeaderContainer = styled(Text)({
  marginTop: "20px",
  marginBottom: "20px",
  fontSize: "48px",
  fontWeight: "bold",
});

const Item = styled(Box)({
  padding: "12px",
  borderRadius: "4px",
  width: "25%",
  minHeight: "25%",
  backgroundColor: "white",
  textAlign: "center",
});

const Column = styled(Flex)({
  flexDirection: "column",
  gap: "6px",
});

const Row = styled(Flex)({
  flexDirection: "row",
  gap: "6px",
});
