import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { Box, Icon, Tooltip } from "@chakra-ui/react";
import React from "react";
import { Image } from "@chakra-ui/react";

export const TooltipCustom: React.FC<any> = (props: {
  label: string;
  fontSize?: any;
  style?: any;
}) => {
  const { label, fontSize, style } = props;

  return (
    <Tooltip label={label} fontSize={fontSize ?? "md"}>
      <Box {...style}>
        <Image
          src="/assets/tooltip.svg"
          alt="Magichire Logo"
          width="24px"
          height="24px"
        />
      </Box>
    </Tooltip>
  );
};
