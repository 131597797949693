import { InputGroup, Flex, Text, Textarea } from "@chakra-ui/react";
import React from "react";
import { useEffect, useState } from "react";

export const TextAreaInput: React.FC<{
  name: string;
  title: string;
  type?: string;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
  onChange?: (e: any) => void;
  disabled?: boolean;
  readonly?: boolean;
  maxChar?: number;
}> = (props: {
  name: string;
  title: string;
  text?: string;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
  onChange?: (e: any) => void;
  disabled?: boolean;
  readonly?: boolean;
  maxChar?: number;
}) => {
  const [charRemaining, setCharRemaining] = useState(props.maxChar ?? 0);

  const change = (e: any) => {
    const { value } = e.target;
    if (!!props.maxChar) {
      setCharRemaining(props.maxChar! - (value?.length ?? 0));
    }

    if (props.onChange) return props.onChange(e);
  };

  useEffect(() => {
    if (!!props.maxChar) {
      setCharRemaining(props.maxChar! - (props.defaultValue?.length ?? 0));
    }
  }, [props.maxChar]);

  return (
    <Flex flexDirection={"column"}>
      <Text fontWeight={600} mb="8px" fontSize={"14px"}>
        {props.title}
        {props.required ? (
          <Text
            as={"span"}
            fontWeight="700"
            fontSize="14px"
            color="#792551"
            ml="3px"
          >
            *
          </Text>
        ) : (
          <></>
        )}
      </Text>
      <InputGroup>
        <Textarea
          p={"12px"}
          whiteSpace="pre-line"
          rows={4}
          name={props.name}
          required={props.required}
          placeholder={props.placeholder}
          onChange={change}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
        />
      </InputGroup>
      {!!props.maxChar ? (
        <Text
          mt={"8px"}
          fontSize={"14px"}
          fontWeight="600"
          color={charRemaining < 0 ? "red" : "#583CE1"}
        >
          Remaining characters {charRemaining}
        </Text>
      ) : (
        <></>
      )}
    </Flex>
  );
};
