import { Center, Flex, Image, useToast, Text, Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LighthouseAnimation } from "../../components/shared/animations/LighthouseAnimation";
import { errorToast } from "../../constants/toast.constants";
import { IdentityService } from "../../service/identity.service";
import { MagiclinkService } from "../../service/third-party/magiclink.service";
import { TokenService } from "../../service/token.service";
import { update } from "../../store/slice/Account/user-profile.slice";
import { updateSidebar } from "../../store/slice/Sidebar/sidebar.slice";
import { tokenSlice } from "../../store/slice/Token/token.slice";
import { getQueryParam } from "../../utils/url.util";

export const LoginRedirect: React.FC<any> = (props: any) => {
  const identityService = new IdentityService();
  const magiclinkService = new MagiclinkService();
  const tokenService = new TokenService();

  const { email, password } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const identityLogin = async (token: string | null) => {
    if (!email) navigate("/unauthorized");

    if (!token) {
      toast(errorToast("Failed token authentication. Please try again", 7500));
      navigate("/auth/login");
    }

    await identityService
      .magicLinklogin(email!, token!, password)
      .then(async (token: any) => {
        dispatch(
          tokenSlice.actions.update({ accessToken: token.accessToken } as any)
        );

        dispatch(updateSidebar({ logoSrc: "", workflows: [] }));
        dispatch(update({ email: email }));

        navigate("/dashboard");
      })
      .catch(() => {
        navigate("/unauthorized");
      });
  };

  const finishEmailRedirectLogin = () => {
    const magicCredential = getQueryParam("magic_credential");

    if (magicCredential) {
      magiclinkService.magic?.auth
        .loginWithCredential()
        .then(async (didToken) => await identityLogin(didToken));
    }
  };

  useEffect(() => {
    finishEmailRedirectLogin();
  }, []);

  return (
    <Box bg="#001D2F" h="100vh" p="12px" borderRadius="8px">
      <Flex mt="30vh" flexDirection="column" color="white">
        <Center>
          <Image
            src="/assets/sidebar/slu-branding.svg"
            alt="Sumlookup Branding"
            width="260px"
            mb={6}
          />
        </Center>

        <Center>
          <LighthouseAnimation />
        </Center>
      </Flex>
    </Box>
  );
};
