import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Image,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ButtonCustom } from "../form/controls/ButtonCustom";

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onConfirm: () => void;
}

export const WorkflowTriggerModal: React.FC<ModalProps> = (
  props: ModalProps
) => {
  const { isOpen, onClose, onConfirm } = props;
  const [disabled, setDisabled] = useState(false);

  const handleConfirm = async () => {
    setDisabled(true);
    onConfirm();
  };

  useEffect(() => {
    setDisabled(false);
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          <Flex flexDir="row" alignItems="center" gap="3px">
            <Image src="/assets/danger.png" w="18px" h="18px" />
            <Box>Trigger Integration</Box>
          </Flex>
        </ModalHeader>

        <ModalBody>
          Are you sure you want to re-run this integration? It cannot be stopped
          once started.
        </ModalBody>
        <ModalFooter>
          <Flex gap="12px">
            <ButtonCustom secondary neutral onClick={onClose}>
              Cancel
            </ButtonCustom>
            <ButtonCustom onClick={handleConfirm} disabled={disabled}>
              Confirm Run
            </ButtonCustom>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
