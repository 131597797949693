import "./App.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { Integrations } from "./views/app/Integrations";
import { History } from "./views/app/History";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store, persistor, RootState } from "./store";
import { Loading } from "./components/shared/Loading";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { MagicLinkLogin } from "./views/auth/MagicLinkLogin";
import { LoginRedirect } from "./views/auth/LoginRedirect";
import { Dashboard } from "./views/app/Dashboard";
import { Sidebar } from "./components/app/Sidebar";
import { useEffect, useState } from "react";
import { Archive } from "./views/app/Archive";
import { Support } from "./views/app/Support";

function App() {
  return (
    <Provider store={store}>
      <ProviderWrapper>
        <PersistGate loading={<Loading loading={true} />} persistor={persistor}>
          <BrowserRouter>
            {/* <ConditionalSidebar /> */}
            <Routes>
              <Route path="*" element={<Navigate to="/dashboard" />} />
              <Route
                path="/unauthorized"
                element={<Navigate to="/auth/app/login" />}
              />
              <Route path="/auth/app/login" element={<MagicLinkLogin />} />

              <Route
                path="/auth/login/redirect/:email/:password"
                element={<LoginRedirect />}
              />
              <Route path="/dashboard" element={<AuthenticatedRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
              </Route>
              <Route path="/integrations" element={<AuthenticatedRoute />}>
                <Route path="/integrations" element={<Integrations />} />
              </Route>
              <Route path="/history/:id" element={<AuthenticatedRoute />}>
                <Route path="/history/:id" element={<History />} />
              </Route>
              <Route path="/archive" element={<AuthenticatedRoute />}>
                <Route path="/archive" element={<Archive />} />
              </Route>
              <Route path="/support" element={<AuthenticatedRoute />}>
                <Route path="/support" element={<Support />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </ProviderWrapper>
    </Provider>
  );
}

const AuthenticatedRoute = (props: { login?: boolean }) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.token);

  // Get from store
  const authenticated = props.login
    ? !!token.loginToken?.length
    : !!token.accessToken?.length;

  // If authorized, return an outlet passed from parent
  return authenticated ? <Outlet /> : <Navigate to="/auth/app/login" />;
};

const ConditionalSidebar = () => {
  const [isLogin, setIsLogin] = useState(true);
  const match = window.location.pathname.startsWith("/auth/app/login");

  useEffect(() => {
    alert(1);
    setIsLogin(match);
  }, [match]);

  return !isLogin ? <Sidebar /> : <></>;
};

const ProviderWrapper = ({ children }) => {
  const email = useSelector((state: RootState) => state.userProfile.email);

  const theme = extendTheme({
    colors: {
      brand: {
        100: "#1D1F31",
        200: "#333448",
      },
    },
  });

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};
export default App;
