export const firstCharToUpper = (str: string) => {
  if (!str) return null;

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const ellipsesText = (str: string, limit: number) => {
  if (!str) return "";

  return str.length > limit ? str.substring(0, limit - 3) + "..." : str;
};
