import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false, // Ensure chart takes up 100% of the container
  plugins: {
    legend: {
      position: "top",
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: "Time (s)",
        font: {
          size: 14,
          weight: "bold",
        },
      },
      grid: {
        drawOnChartArea: false, // remove y-axis gridlines
      },
    },
    x: {
      title: {
        display: true,
        text: `Day of ${new Date().toString().split(" ")[1]}`,
        font: {
          size: 14,
          weight: "bold",
        },
      },
      grid: {
        drawOnChartArea: false, // remove x-axis gridlines
      },
    },
  },
};

const labels = generateSequentialArray();

function generateSequentialArray(): number[] {
  const today = new Date();
  const dayOfMonth = today.getUTCDate();

  const arr = Array.from({ length: dayOfMonth }, (_, i) => i + 1);
  return arr;
}

function parseDay(dateString: string): number {
  const date = new Date(dateString);
  return date.getUTCDate();
}

function calculateAverage(arr: number[]): number {
  const sum = arr.reduce((a, b) => a + b, 0);
  return sum / arr.length;
}

function transformData(items: any[]): any[] {
  const groupedData: { [key: string]: { [key: number]: number[] } } = {};

  for (const item of items) {
    const day = parseDay(item.lastRunDate);

    if (!groupedData[item.friendlyName]) {
      groupedData[item.friendlyName] = { [day]: [item.duration] };
    } else if (!groupedData[item.friendlyName][day]) {
      groupedData[item.friendlyName][day] = [item.duration];
    } else {
      groupedData[item.friendlyName][day].push(item.duration);
    }
  }

  const result = [];
  const today = new Date().getUTCDate();

  for (const friendlyName in groupedData) {
    const data = [];

    for (let day = 1; day <= today; day++) {
      if (groupedData[friendlyName][day]) {
        const averageTime = calculateAverage(groupedData[friendlyName][day]);
        data.push(averageTime);
      } else {
        data.push(0);
      }
    }

    const colors = getColors();
    result.push({ label: friendlyName, data, ...colors });
  }

  return result;
}

function getColors() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  // Create color strings
  const borderColor = `rgb(${r}, ${g}, ${b})`;
  const backgroundColor = `rgba(${r}, ${g}, ${b}, 0.5)`;

  return { borderColor, backgroundColor };
}

export const RunTimeChart: React.FC<any> = (props: any) => {
  const { data } = props;
  const [chartProps, setChartProps] = useState();

  const init = async () => {
    if (!data?.length) {
      return;
    }

    const labels = generateSequentialArray();
    const transformedData = transformData(data);

    const props = {
      labels,
      datasets: transformedData,
    };

    setChartProps(props as any);
  };

  useEffect(() => {
    init();
  }, [data]);

  return !chartProps ? (
    <>Loading...</>
  ) : (
    <Line options={options as any} data={chartProps} />
  );
};
