import { Box, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";

export const StatusTag: React.FC<{
  children: any;
  type: "completed" | "warning" | "error" | "failure" | "queued" | null;
  style?: any;
  hidden?: boolean;
}> = (props: any) => {
  const { type, children, style } = props;

  const [indicatorProps, setIndicatorProps] = useState({} as any);

  const indicatorColor = () => {
    if (type === "completed" || type === "success") {
      setIndicatorProps({
        indicator: "#56CB62",
        text: "#166534",
        bg: "#DCFCE7",
      });

      return;
    }

    if (type === "error" || type === "failure") {
      setIndicatorProps({
        indicator: "#FEE2E2",
        text: "#FEE2E2",
        bg: "rgb(172,15,15)",
      });

      return;
    }

    if (type === "cancelled" || type === "warning") {
      setIndicatorProps({
        indicator: "#B91C1C",
        text: "#B91C1C",
        bg: "#FEE2E2",
      });

      return;
    }

    if (!children) {
      setIndicatorProps({
        indicator: "rgb(248,75,0)",
        text: "rgb(248,75,0)",
        bg: "#F8D1B0",
      });

      return;
    }

    setIndicatorProps({
      indicator: "#3f3e42",
      text: "#3f3e42",
      bg: "#929099",
    });
  };

  useEffect(() => {
    indicatorColor();
  }, [type]);

  return (
    <BaseTag
      pb="5px"
      hidden={props.hidden}
      color={indicatorProps.text}
      bg={indicatorProps.bg}
      width="80px"
    >
      <Flex flexDirection="row" alignItems={"center"} gap={1}>
        <Box
          {...style}
          bg={indicatorProps.indicator}
          w="6px"
          h="6px"
          fontSize="12px"
          borderRadius="50%"
        ></Box>{" "}
        {!children ? "Pending" : children}
      </Flex>
    </BaseTag>
  );
};

const BaseTag = styled(Box)({
  borderRadius: "100px",
  padding: "4px 8px 4px 8px",
  fontWeight: 600,
  fontSize: "12px",
});
