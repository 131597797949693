import { LinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Link,
  Text,
  Image,
  Center,
  InputLeftAddon,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Layout from "../../components/app/Layout";
import BaseContainer from "../../components/form/BaseContainer";
import { DropdownInput } from "../../components/form/controls/DropdownInput";
import { FormInput } from "../../components/form/controls/FormInput";
import { Loading } from "../../components/shared/Loading";
import { IntegrationItem } from "../../models/integration-item.model";
import { IntegrationService } from "../../service/integration.service";

const baseContainerStyles = {
  flexGrow: "1",
  borderRadius: { base: "0px", lg: "8px 8px 0 0" },
  minH: { base: "calc(100vh - 80px - 24px)" },
  paddingBottom: "50px",
};

const integrationService = new IntegrationService();

export const Archive: React.FC<any> = (props: any) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [repoDropdown, setRepoDropdown] = useState([] as string[]);

  const [integrations, setIntegrations] = useState([] as IntegrationItem[]);
  const [selectedRepo, setRepoSelected] = useState({} as IntegrationItem);
  const [files, setFiles] = useState({} as any);
  const [downloadLink, setDownloadLink] = useState("");

  const [activeValue, setActiveValue] = useState("");

  const init = async () => {
    setLoading(true);
    await integrationService
      .getWorkflowsWithRuns({
        limit: 1,
      })
      .then((integrations) => {
        setIntegrations(integrations);
        setRepoDropdown(integrations.map((x) => x.friendlyName));
      })
      .finally(() => setLoading(false));
  };

  const handleDropdownChange = async (dropdownIndex: number) => {
    reset();
    setLoading(true);
    const friendlyName = repoDropdown[+dropdownIndex];

    const item = integrations.find((x) => x.friendlyName === friendlyName);
    setRepoSelected(item);

    await integrationService
      .getRepositoryFileList(item.repoName, item.name)
      .then((files) => {
        if (Array.isArray(files.log) && files.log.length > 0) {
          files.log.sort((a, b) => {
            return new Date(b.split('.zip')[0]).getTime() - new Date(a.split('.zip')[0]).getTime();
          });
        
          console.log(files.log);
        }
        

        setFiles(files);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFileClick = async (filename: string) => {
    const url = await integrationService.getArchiveFileDownloadLink(
      selectedRepo.repoName,
      selectedRepo.name,
      filename
    );

    setActiveValue(filename);
    setDownloadLink(url);
  };

  const reset = () => {
    setRepoSelected({} as IntegrationItem);
    setFiles({} as any);
    setDownloadLink("");
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Layout>
      <Loading loading={loading} />
      <BaseContainer styles={baseContainerStyles}>
        <Flex flexDir="column" gap="26px">
          <Box>
            <StepTitle step={1} text="Select the integration" />
            <DropdownInput
              options={repoDropdown}
              onChange={(e) => handleDropdownChange(e.target.value)}
            ></DropdownInput>
          </Box>

          <Box hidden={!selectedRepo?.id} >
            <StepTitle step={2} text="Select the files to download" />
            <Box border="1px solid lightgray" p="20px" borderRadius="6px" maxHeight="300px" overflowY="auto">
              {!loading ? (
                !!Object.keys(files)?.length ? (
                  <FileTree
                    data={files}
                    activeValue={activeValue}
                    onClick={(file: string) => handleFileClick(file)}
                  />
                ) : (
                  <Text textAlign="center">
                    No archived files available at this time.
                  </Text>
                )
              ) : (
                <Loading loading={loading} />
              )}
            </Box>
          </Box>

          <Box hidden={!downloadLink?.length} mb="16px">
            <StepTitle step={3} text="Click on the link to download the file" />
            <Box bg="lightgray" p="20px" borderRadius="6px">
              <Flex
                cursor="pointer"
                onClick={() => window.open(downloadLink, "_blank")}
                textAlign="center"
                flexDir="column"
                gap="12px"
              >
                <Center>
                  <Image
                    src="/assets/download.png"
                    w="32px"
                    h="32px"
                    mt="4px"
                  />
                </Center>
                <Text>Click to download:</Text>
                <Text fontWeight="bold">{activeValue}</Text>
              </Flex>
            </Box>

            <FormInput
              name="downloadLink"
              title="Copy download URL (1 hour expiration)"
              defaultValue={downloadLink}
              disabled
              leftAddon={
                <InputLeftAddon
                  _hover={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator.clipboard.writeText(downloadLink);
                    toast({
                      title: "Link copied to clipboard.",
                      status: "success",
                      duration: 3000,
                    });
                  }}
                  px="8px"
                  bg="transparent"
                  // eslint-disable-next-line
                  children={
                    <Image
                      src="/assets/copy-file.svg"
                      alt="Copy Link"
                      width="24px"
                      height="24px"
                    />
                  }
                />
              }
            />
          </Box>
        </Flex>
      </BaseContainer>
    </Layout>
  );
};

const StepTitle = (props: { step: 1 | 2 | 3; text: string }) => {
  const { step, text } = props;

  return (
    <Flex flexDir="row" my="8px" fontSize="18px" fontWeight="bold" alignItems="center">
      <Box
        textAlign="center"
        w="24px"
        h="24px"
        borderRadius="50%"
        mr="8px"
        bg="#BF326A"
        color="white"
        fontSize="15px"
      >
        {step}
      </Box>
      {text}
    </Flex>
  );
};

const FileTree = ({ data, onClick, activeValue }) => {
  return (
    <Flex flexDir="column">
      {Object.keys(data).map((key) => {
        const value = data[key];
        return (
          <Flex key={key}>
            {typeof value === "object" ? (
              <div>
                <Flex flexDir="row" gap="4px" mt="8px">
                  <Link as={LinkIcon} mt="4px" w="14px" h="14px" /> {key}
                </Flex>
                <FileTree
                  data={value}
                  onClick={onClick}
                  activeValue={activeValue}
                />
              </div>
            ) : (
              <Flex
                bg={activeValue === value ? "lightgray" : "transparent"}
                px="4px"
                borderRadius="4px"
                flexDir="row"
                ml="8px"
                hidden={!value?.length}
                onClick={() => onClick(value)}
              >
                <Flex cursor="pointer" fontWeight="500" flexDir="row" gap="4px">
                  <Image src="/assets/file.png" w="16px" h="16px" mt="4px" />
                  {value}
                </Flex>
              </Flex>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};
