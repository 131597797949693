import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IdentityService } from "../../service/identity.service";
import { MagiclinkService } from "../../service/third-party/magiclink.service";
import {
  Flex,
  Stack,
  Heading,
  Text,
  Button,
  Center,
  Image,
  Box,
} from "@chakra-ui/react";
import { FormInput } from "../../components/form/controls/FormInput";
import { update } from "../../store/slice/Token/token.slice";

export const MagicLinkLogin: React.FC<any> = (props: any) => {
  const magiclinkService = new MagiclinkService();
  const identityService = new IdentityService();

  const dispatch = useDispatch();
  const [formState, setFormState] = useState({ email: "", password: "" });

  const onChange = (e: any) => {
    const { name, value } = e.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    await magiclinkService
      .handleLoginWithEmail(formState.email, formState.password)
      .then(() => {
        window.open("about:blank", "_self");
        window.close();
      })
      .catch((err) => {
        alert(err);
        return;
      })
      .finally(() => {});
  };

  const logout = async () => {
    const magiclinkService = new MagiclinkService();
    await magiclinkService.logout();
    dispatch(update({}));
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <Flex minH={"100vh"} w="100vw" bg="#F8F9FC">
      <Flex flexDir="row">
        <Box position="relative" h="100vh" w="65vw" overflow="hidden">
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transform: "translate(-50%, -50%)",
              zIndex: 1
            }}
          >
            <source src="/assets/homepage-animation.mp4" type="video/mp4" />
          </video>
          <Flex
            flexDir="column"
            color="white"
            bg="rgba(0, 0, 0, 0.6)"
            p="16px"
            borderRadius="12px"
            position="absolute"
            w="40vw"
            maxW="600px"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex={2}
            style={{ backdropFilter: "blur(5px)" }}
          >
            <Text fontWeight={600} fontSize="24px">
              Experience connected planning with
              <br />
              <Text as="span" color="#7A2551" fontWeight="700">
                {"["}
              </Text>
              SUMLOOKUP
              <Text as="span" color="#7A2551" fontWeight="700">
                {"]"}
              </Text>{" "}
              Lighthouse.
            </Text>
            <Center>
              <Text mt="30px" fontWeight={400} fontSize="18px">
                View, manage, and control your suite of managed services from
                us. We aim to provide visibility in all facets of our service
                offerings to ensure that you are always in control.
              </Text>
            </Center>
          </Flex>
          <Image
            pos="absolute"
            left="10px"
            bottom="10px"
            src="/assets/sidebar/slu-branding.svg"
            alt="Sumlookup Branding"
            width="180px"
            mb={2}
          />
        </Box>
        <Flex color="black" px="5vw" align={"center"} justify={"center"}>
          <form onSubmit={onSubmit}>
            <Stack spacing={4} w={"full"} maxW={"lg"} p={6} my={12}>
              <Text fontWeight="500" fontSize="28px">
                Welcome back.
              </Text>
              <FormInput
                name="email"
                type="email"
                title="Email"
                required
                onChange={onChange}
              />
              <FormInput
                name="password"
                title="Anaplan Token"
                required
                tooltipText="You can find this token in the module created in Anaplan by your SUMLOOKUP consultant."
                onChange={onChange}
              />
              <Stack spacing={6}>
                <Button
                  type="submit"
                  bg="#792551"
                  color="white"
                  _hover={{
                    color: "#792551",
                    bg: "white",
                    border: "1px solid #792551",
                  }}
                >
                  Login
                </Button>
              </Stack>

              <Center>
                <Text fontSize="12px">
                  Request your site admin to whitelist your email for access.
                </Text>
              </Center>
            </Stack>
          </form>
          <Box fontSize="12px" pos="absolute" bottom="10px" right="10px">
            © {new Date().getFullYear()} All rights reserved. SUMLOOKUP PTY LTD.
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
