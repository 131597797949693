import { createSlice } from "@reduxjs/toolkit";

export interface Sidebar {
  logoSrc: string;
  workflows: { id: string; friendlyName: string }[];
}

export const initialState: Sidebar = {
  logoSrc: "",
  workflows: [],
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    updateSidebar: (state, params) => {
      const f = params.payload as Sidebar;

      state.logoSrc = f.logoSrc;
      state.workflows = f.workflows;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
