import { BaseService } from "./base.service";

export class IdentityService extends BaseService {
  // Login to access MagicLink page
  async tokenLogin(password: string) {
    const resp = await this.client.post("/identity/user/token/login", {
      password,
    });

    return resp.data;
  }

  // Main App login Page
  async magicLinklogin(email: string, didToken: string, password: string) {
    const resp = await this.client.post("/identity/user/login", {
      email,
      didToken,
      password,
    });

    return resp.data;
  }
}
