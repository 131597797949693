const env =
  process.env.REACT_APP_ENV || process.env.ENVIRONMENT || process.env.NODE_ENV;
const isProd =
  env && (env.toLowerCase() === "prod" || env.toLowerCase() === "production");

let apiUrl = "http://localhost:9000";
let instance = "local";
let magiclinkKey = "pk_live_676DCE89592B81F6";

if (true) {
  apiUrl = "https://api.sumlookup.com";
  instance = "prod";
  // Set production
  magiclinkKey = "pk_live_E7E7E8386A6AFD16";
}

const config = {
  apiUrl,
  instance,
  magiclinkKey,
};

export default config;
