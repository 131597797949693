import { LinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { IntegrationItem } from "../../models/integration-item.model";
import { IntegrationService } from "../../service/integration.service";
import { ButtonCustom } from "../form/controls/ButtonCustom";
import { DropdownInput } from "../form/controls/DropdownInput";
import { FlexIconRow } from "../shared/FlexIconRow";
import { ExportIcon } from "../shared/icons/icons";
import { Loading } from "../shared/Loading";

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  integrations: IntegrationItem[];
}

const integrationService = new IntegrationService();

export const ExportArchiveModal: React.FC<ModalProps> = (props: ModalProps) => {
  const { isOpen, onClose, integrations } = props;
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [repoDropdown, setRepoDropdown] = useState([] as string[]);

  const [selectedRepo, setRepoSelected] = useState({} as IntegrationItem);
  const [files, setFiles] = useState({} as any);
  const [downloadLink, setDownloadLink] = useState("");

  const [activeValue, setActiveValue] = useState("");

  const initDropdown = async () => {
    setRepoDropdown(integrations.map((x) => x.friendlyName));
  };

  const handleDropdownChange = async (dropdownIndex: number) => {
    setLoading(true);
    const friendlyName = repoDropdown[+dropdownIndex];

    const item = integrations.find((x) => x.friendlyName === friendlyName);
    setRepoSelected(item);

    await integrationService
      .getRepositoryFileList(item.repoName, item.name)
      .then((files) => {
        setFiles(files);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFileClick = async (filename: string) => {
    const url = await integrationService.getArchiveFileDownloadLink(
      selectedRepo.repoName,
      selectedRepo.name,
      filename
    );

    setActiveValue(filename);
    setDownloadLink(url);
    console.log(url);
  };

  const handleClose = async () => {
    reset();
    onClose();
  };

  const reset = () => {
    setRepoSelected({} as IntegrationItem);
    setFiles({} as any);
    setDownloadLink("");
  };

  useEffect(() => {
    setDisabled(false);
  }, []);

  useEffect(() => {
    initDropdown();
  }, [integrations]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FlexIconRow>
            <ExportIcon fill="darkgray" /> <Box fontWeight="600" fontSize="18px">Export Archives</Box>
          </FlexIconRow>
        </ModalHeader>

        <ModalBody>
          <Text fontSize="14px">Download archives of previously run integration files. If you don't see any available, please contact support, and we will assist you.</Text>
          <Text my="6px" mt="24px">
            1. Select the integration
          </Text>
          <DropdownInput
            options={repoDropdown}
            onChange={(e) => handleDropdownChange(e.target.value)}
          ></DropdownInput>

          <Box hidden={!selectedRepo?.id}>
            <Text my="6px" mt="24px">
              2. Select the files to download
            </Text>
            <Box border="1px solid lightgray" p="20px" borderRadius="6px" maxHeight="300px" overflowY="auto">
              {!loading ? (
                !!Object.keys(files)?.length ? (
                  <FileTree
                    data={files}
                    activeValue={activeValue}
                    onClick={(file: string) => handleFileClick(file)}
                  />
                ) : (
                  <Text textAlign="center">
                    No archived files available at this time.
                  </Text>
                )
              ) : (
                <Loading loading={loading} />
              )}
            </Box>
          </Box>

          <Box hidden={!downloadLink?.length}>
            <Text my="6px" mt="24px">
              3. Click on the link to download the file
            </Text>
            <Box bg="lightgray" p="20px" borderRadius="6px">
              <Flex
                cursor="pointer"
                onClick={() => window.open(downloadLink, "_blank")}
                textAlign="center"
                flexDir="column"
                gap="12px"
              >
                <Center>
                  <Image
                    src="/assets/download.png"
                    w="32px"
                    h="32px"
                    mt="4px"
                  />
                </Center>
                <Text>Click to download</Text>
              </Flex>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Flex gap="12px">
            <ButtonCustom onClick={handleClose}>Close</ButtonCustom>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const FileTree = ({ data, onClick, activeValue }) => {
  return (
    <Flex flexDir="column">
      {Object.keys(data).map((key) => {
        const value = data[key];
        return (
          <Flex key={key}>
            {typeof value === "object" ? (
              <div>
                <Flex flexDir="row" gap="4px" mt="8px">
                  <Link as={LinkIcon} mt="4px" w="14px" h="14px" /> {key}
                </Flex>
                <FileTree
                  data={value}
                  onClick={onClick}
                  activeValue={activeValue}
                />
              </div>
            ) : (
              <Flex
                bg={activeValue === value ? "lightgray" : "transparent"}
                px="4px"
                borderRadius="4px"
                flexDir="row"
                ml="8px"
                hidden={!value?.length}
                onClick={() => onClick(value)}
              >
                <Flex cursor="pointer" fontWeight="500" flexDir="row" gap="4px">
                  <Image src="/assets/file.png" w="16px" h="16px" mt="4px" />
                  {value}
                </Flex>
              </Flex>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};
