import { createSlice } from "@reduxjs/toolkit";

export interface UserProfile {
  email: string;
}

export const initialState: UserProfile = {
  email: "",
};

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    update: (state, params) => {
      const f = params.payload as UserProfile;

      state.email = f.email;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = userProfileSlice.actions;

export default userProfileSlice.reducer;
