export const getQueryParam = (param: string) => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let val = params.get(param);
  return val;
};

export const getShareJobUrl = (jobId: string) => {
  if (typeof window === "undefined") return "";

  return `${window.location.origin}/public/job/${jobId}`;
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const openTab = (url: string) => {
  if (typeof window === "undefined") return;

  var win = window.open(formatUrl(url), "_blank");
  win!.focus();
};

export const formatUrl = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    return "https://" + url;
  }

  return url;
};
