import { FailureChartProps } from "../../constants/chart.constants";
import { IntegrationItem } from "../../models/integration-item.model";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { useEffect, useState } from "react";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  data: IntegrationItem[];
}

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
  options: {
    maintainAspectRatio: false,
  },
};

export const FailureChart: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const [chartProps, setChartProps] = useState();
  const [guid, setGuid] = useState(0);

  const init = () => {
    let failureChartProps = FailureChartProps as any;
    failureChartProps.datasets[0].data = [
      +data.filter((x) => x.conclusion === "failure").length,
      +data.filter((x) => x.conclusion !== "failure").length,
    ];

    setChartProps(failureChartProps);
    setGuid(guid + 1);
  };

  useEffect(() => {
    init();
  }, [data]);

  return !chartProps ? (
    <>Loading...</>
  ) : (
    <Doughnut options={options} data={chartProps} key={guid} redraw={true} />
  );
};
