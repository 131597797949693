import Lottie from "react-lottie";
import animationData from "../../../animations/lighthouse.json";
import { Box } from "@chakra-ui/react";

export const LighthouseAnimation = ({ text = undefined, ...props }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box h="fit-content" w="fit-content" {...props}>
      <Lottie
        options={defaultOptions}
        height={300}
        width={300}
      />
      {text && <p>{text}</p>}
    </Box>
  );
};
