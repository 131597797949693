import { BaseService } from "./base.service";

export class SupportService extends BaseService {
  constructor() {
    super();
  }

  async send(data: any) {
    await this.client.post(`/support/send`, data);
  }
}
