import axios, { AxiosInstance } from "axios";
import config from "../configs/index";
import { store } from "../store";
import { tokenSlice, initialState } from "../store/slice/Token/token.slice";

export class BaseService {
  protected client: AxiosInstance;
  protected isPublicRoute: boolean;

  constructor(baseUrl?: string, isPublicRoute: boolean = false) {
    this.client = axios.create({
      baseURL: baseUrl ?? config.apiUrl,
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
    });

    this.isPublicRoute = isPublicRoute;
    this.setupRequestInterceptors();
    this.setupResponseInterceptors();
  }

  protected set setIsPublicRoute(isPublic: boolean) {
    this.isPublicRoute = isPublic;
  }

  protected setupRequestInterceptors() {
    if (this.isPublicRoute) return;
    this.client.interceptors.request.use(async (request: any) => {
      const token = store.getState().token.accessToken;
      // if does not exist then Auth header should not be set
      if (request.headers) {
        if (!request.headers.Authorization) {
          request.headers.Authorization = `Bearer ${token}`;
        }
      }
      return request;
    });
  }

  private setupResponseInterceptors() {
    this.client.interceptors.response.use(undefined, async (error) => {
      const response = error.response;
      if (response) {
        if (response.status === 401) {
          if (error.config && !error.config.__isRetryRequest) {
            error.config.__isRetryRequest = true;
            return this.client(error.config);
          } else {
            store.dispatch(tokenSlice.actions.update(initialState));
            const redirectRoute = "/session-end";
            if (window.location.pathname !== redirectRoute)
              window.location.href = redirectRoute;
          }
        }
      }
      return Promise.reject(error);
    });
  }
}
