import {
  Table as ChakraTable,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

export const TableContainer: React.FC<any> = ({ columns, children }) => {
  return (
    <ChakraTable
      size="sm"
      variant="simple"
      style={{ tableLayout: "fixed", width: "100%", height: "1px" }}
    >
      <Thead>
        <Tr>
          {columns.map((column: any, index: number) => (
            <Th key={index} style={{
              position: "sticky",
              top: "0",
              paddingTop: "14px",
              paddingBottom: "14px",
              zIndex: 1,
              backgroundColor: "#FBFBF8",
            }}
            {...column.style}
            >
              {column.header}
            </Th>
          ))}
        </Tr>
      </Thead>

      {children}
    </ChakraTable>
  )

}